<template>
  <div class="special-class-details">
    <!-- 专题课程详情 -->
    <div
      class="pform"
      style="
        background-color: #ffffff;
        box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
        border-radius: 8px;
      "
    >
      <el-page-header
        style="
          width: 100%;
          height: 70px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-left: 10px;
        "
        @back="back"
        content="课程详情"
      >
      </el-page-header>
      <div style="width: 100%">
        <template v-if="empty">
          <a-row
            type="flex"
            justify="center"
            style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
          >
            <el-empty :description="description"></el-empty>
          </a-row>
        </template>
        <div
          v-else
          v-loading="loading"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)"
        >
          <div class="details-header">
            <img :src="info.imgUrl" alt="" class="banner-img" mode="aspect-fill" />
          </div>
          <div class="pform--details">
            <div class="pform--details--title">
              {{ info.specialSubjectName }}
            </div>
            <div class="pform--details--description">
              {{ info.desc }}
            </div>
          </div>
          <div class="pform--user">
            <el-image
              class="pform--user--avatar"
              src="http://swlm.oss-cn-guiyang-gzdata-d01-a.res.gzdata.com.cn/servlet/file/1637047130291.png"
              fit="cover"
            ></el-image>
            <div class="pform--user--name">{{ info.departmentName }}</div>
            <div class="pform--user--time">{{ info.addTime }}</div>
            <div class="pform--user--count">{{ info.learnedCount }}人已参加</div>
          </div>
          <!-- 具体专题课程列表数据 -->
          <!-- 视频课程 / 图文课程 标签栏 -->
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="视频课程" name="first"></el-tab-pane>
            <el-tab-pane label="图文课程" name="second"></el-tab-pane>
          </el-tabs>
          <!-- 使用组件 -->
          <div>
            <div v-if="tabIndex === 0 && true" style="height: 100%; width: 100%">
              <class-details :id="specialSubjectId"></class-details>
            </div>
            <div v-else>
              <image-class :id="specialSubjectId"></image-class>
            </div>
          </div>
        </div>
      </div>
      <!-- 去答题 -->
      <div style="width: 100%; margin: 30px auto 0 auto; padding-bottom: 30px">
        <a-button
          style="
            border-radius: 25px;
            background: linear-gradient(90deg, #51d2be, #0090d7);
            font-size: 15px;
            color: #fefefe;
            font-weight: bold;
            height: 50px;
            width: 340px;
            border: none;
          "
          @click="goTest()"
          >专题练习题
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
// 引入视频课程组件
import ClassDetails from "../../components/special-class-list/special-class-list-details.vue";
// 引入图文课程组件
import ImageClass from "../../components/special-class-list/special-image-class-list.vue";
export default {
  data() {
    return {
      specialSubjectId: "", // 专题课程id
      info: {}, // 专题课程对象数据
      description: "暂无数据",
      empty: false,
      loading: true,
      activeName: "first", // 标签栏数据
      tabIndex: 0,
      specialId: "",
    };
  },
  computed: {
    ...mapState(["fullWidth", "num1"]),
  },
  created() {
    this.onloadPage();
    this.specialSubjectId = this.$route.query.id;
    this.loading = true;
    this.empty = true;
    this.getSpecialCourseDetails();
  },
  mounted() {
    if (this.num1 === 1) {
      this.tabIndex = this.num1;
      this.activeName = "second";
    } else {
      this.tabIndex = this.num1;
      this.activeName = "first";
    }
  },
  components: {
    ClassDetails,
    ImageClass,
  },
  methods: {
    back() {
      this.$router.push("/specialclass/specialcard");
    },
    // 浏览器刷新时
    onloadPage() {},
    getSpecialCourseDetails() {
      this.loading = true;
      this.$HTTP
        .httpToken({
          url: "/classroom/getSpecialSubjectById",
          method: "post",
          data: {
            specialSubjectId: this.specialSubjectId,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.code == 0 && res.data) {
            console.log("专题课程详情", res);
            this.empty = false;
            this.info = res.data;
            this.specialId = res.data.topicId;
          } else {
            this.empty = true;
            this.description = "暂无数据";
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.empty = true;
          this.description = e;
        });
    },
    // 标签栏方法占位
    handleClick(tab, event) {
      console.log("tab", tab);
      this.tabIndex = Number(tab.index);
    },
    goTest() {
      this.$store.dispatch("set_specialId", this.specialSubjectId);
      this.$router.push({
        name: "TestRegister",
        query: { specialId: this.specialId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.special-class-details {
  width: 100%;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pform {
  width: 100%;
  height: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &--user {
    height: 60px;
    width: 100%;
    display: flex;
    padding: 0 10px;
    box-sizing: border-box;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid rgba(240, 240, 240, 100);
    &--avatar {
      width: 40px;
      height: 40px;
    }
    &--name {
      margin-left: 10px;
      color: #101010;
      font-size: 16px;
      font-weight: 700;
    }
    &--time {
      margin-left: 10px;
      color: #aeaeae;
      font-size: 14px;
    }
    &--count {
      flex: 1;
      text-align: right;
      color: #cbcbcd;
      font-size: 14px;
    }
  }
  &--details {
    display: flex;
    flex-direction: column;
    margin-bottom: 6px;
    width: 100%;
    &--title {
      width: 100%;
      height: 50px;
      color: #000000;
      font-size: 16px;
      font-weight: 700;
      line-height: 50px;
      text-align: left;
      padding: 0 10px;
      box-sizing: border-box;
    }
    &--description {
      text-align: left;
      padding: 0 10px;
    }
  }
}

@media screen and (max-width: 800px) {
  .pform {
    width: 100%;
  }
}

@media screen and (min-width: 800px) {
  .pform {
    width: 70%;
  }
}
.banner-img {
  aspect-ratio: 16/9;
  width: 100%;
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
::v-deep .el-tabs__header {
  padding: 0 0 0 20px;
}
</style>
