<template>
  <div class="content">
    <div style="width: 100%">
      <template v-if="empty">
        <a-row type="flex" justify="center" style="margin-bottom: 10px; margin-top: 10px; min-height: 400px">
          <el-empty :description="description"></el-empty>
        </a-row>
      </template>
      <template v-else>
        <a-row :gutter="24" style="margin-top: 20px; min-height: 500px" v-loading="loading" element-loading-text="加载中"
          element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.2)">
          <!-- 课程列表 -->
          <a-col :span="12" :lg="6" v-for="(item, index) in courseList" :key="item.classRoomId"
            @click="onItemClick(index)">
            <div
              style="box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.09);border-radius: 8px;overflow: hidden;margin-bottom: 24px;cursor: pointer;">
              <div style="position: relative">
                <el-image style="width: 100%; height: 156px" :src="item.coverUrl" fit="cover"></el-image>
                <!-- 进度 -->
                <div class="right-top"
                  style="display: flex;align-items: center;position: absolute;top: 0;right: 0;background-color: rgba(0, 0, 0, 0.4);padding: 4px 8px;border-radius: 8px 0 8px 0;">
                  <div style="font-size: 12px;color: #eee;font-weight: 400;margin-left: 3px;">
                    {{ Math.ceil(item.totalProgress) }}%
                  </div>
                </div>
                <template>
                  <!-- 播放按钮 -->
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="24" height="24"
                    style="fill: rgba(255, 255, 255, 0.67);border-color: rgba(0, 0, 0, 0);border-width: bpx;border-style: undefined;position: absolute;top: 0;right: 0;bottom: 0;left: 0;margin: auto;"
                    filter="drop-shadow(0px 0px 6px rgba(0,0,0,0.4))">
                    <path
                      d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z">
                    </path>
                  </svg>
                </template>
              </div>
              <div style="padding: 8px">
                <div class="u-line-2"
                  style="font-size: 14px;color: #101010;font-weight: bold;height: 40px;margin-bottom: 8px;">
                  {{ item.desc }}
                </div>
                <div class="course-cate">
                  <div class="mt-8" style="text-align:left">
                    <div v-for="(tag, index) in item.courseTypeNames" :key="index"
                      style="display: inline-block;font-size: 12px;color: #0e9bd2;background-color: #ddf6ff;border: 1px solid #0e9bd2;border-radius: 4px;padding: 2px 10px;margin-right: 5px;margin-bottom: 5px;">
                      {{ tag }}
                    </div>
                  </div>
                </div>
                <div class="mt-8"
                  style="display: flex;align-items: center;margin-bottom: 8px;justify-content: space-between;">
                  <div style="display: flex; flex-direction: row; align-items: center">
                    <img :src="item.avatar" width="18px" height="18px" style="border-radius: 50%" />
                    <div style="font-size: 12px;color: #868686;font-weight: 400;margin-left: 6px;">
                      {{ item.nickName }}
                    </div>
                  </div>
                  <div style="font-size: 12px; color: #a8a8a8">
                    {{ `${item.viewCount}人已观看` }}
                  </div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      courseList: [],
      loading: true,
      empty: false,
      description: "",
      classRoomId: "",
      sumProgress: 0,
    };
  },
  methods: {
    // 根据id获取课程数据
    getListDetails() {
      this.loading = true;
      this.$HTTP
        .httpToken({
          url: "/classroom/getCloudClassRoomInfoListBySpecialSubjectId",
          method: "post",
          data: {
            specialSubjectId: this.id,
          },
        })
        .then((res) => {
          this.loading = false;
          console.log("专题课程", res);
          // this.classRoomId = res.data[0].classRoomId;
          console.log("classRoomId", this.classRoomId);
          if (res.code == 0 && res.data && res.data.length > 0) {
            this.empty = false;
            this.courseList = res.data;

            // 计算专题课程总进度
            this.courseList.forEach((v) => {
              this.sumProgress = this.sumProgress + v.totalProgress;
              if (v.courseTypeNames && v.courseTypeNames.length > 2) {
                v.courseTypeNames.length = 2;
              }
            });
            this.sumProgress = (this.sumProgress / this.courseList.length).toFixed(2);

            this.$store.dispatch("set_sumProgress", this.sumProgress);

            console.log(this.sumProgress);
          } else {
            this.empty = true;
            this.description = "暂无数据";
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.empty = true;
          this.description = e;
        });
    },

    // 点击课程进入课程详情页
    onItemClick(index) {
      this.$router.push({
        path: "/classroom/details",
        query: {
          id: this.courseList[index].classRoomId,
          specialSubjectId: this.id,
          specialTopic: true,
        },
      });
    },
  },
  created() {
    this.getListDetails();
  },
};
</script>

<style lang="scss" scoped>
.course-cate {
  margin: 12px 0;
}

.content {
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

::v-deep .u-line-2 {
  text-align: left;
}
</style>
