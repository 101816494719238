<template>
  <div>
    <div style="width: 100%">
      <template v-if="empty">
        <a-row
          type="flex"
          justify="center"
          style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
        >
          <el-empty :description="description"></el-empty>
        </a-row>
      </template>
      <div
        v-else
        v-loading="loading"
        element-loading-text="加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.2)"
      ></div>
    </div>
    <!-- 图文数据 -->
    <div class="image-list">
      <div
        v-for="(item, index) in articleList"
        :key="index"
        class="flex-row"
        style="
          display: flex;
          border-bottom: 1px solid #eee;
          margin: 20px 0;
          cursor: pointer;
        "
        @click="handleImageCourse(item.newsId)"
      >
        <div class="flex-col" style="flex: 1">
          <div class="title-conent">
            {{ item.title ? item.title : "" }}
          </div>
          <div class="from-conent">{{ "来源：" + (item.from ? item.from : "") }}</div>
        </div>
        <img
          class="cover-img"
          style="border-radius: 10rpx; margin-left: 24rpx"
          v-if="item.coverImg && item.coverImg.length > 0"
          :src="item.coverImg[0]"
          mode="aspectFill"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { title } from "process";

// import { mapState } from "vuex";
export default {
  props: {
    id: {
      type: String,
    },
  },
  data() {
    return {
      articleList: [],
      loading: true,
      empty: false,
      description: "暂无数据",
      imgUrl: "",
      departName: "",
    };
  },
  computed: {
    // ...mapState(["departName"]),
  },
  methods: {
    getArticleList() {
      this.loading = true;
      this.$HTTP
        .httpToken({
          url: "/classroom/getSpecialSubjectArticleListBySpecialSubjectId",
          method: "post",
          data: {
            specialSubjectId: this.id,
          },
        })
        .then((res) => {
          this.loading = false;
          console.log("图文课程", res);
          if (res.code == 0 && res.data && res.data.length > 0) {
            this.empty = false;
            this.articleList = res.data;
          } else {
            this.empty = true;
            this.description = "暂无数据";
          }
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
          this.empty = true;
          this.description = e;
        });
    },
    handleImageCourse(id) {
      this.$router.push({
        name: "ArticleDetails",
        query: { specialSubjectArticleId: id },
      });
    },
  },
  created() {
    this.getArticleList();
    this.departName = JSON.parse(localStorage.getItem("department"));
  },
};
</script>

<style lang="scss" scoped>
.flex-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  justify-content: space-around;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.title-conent {
  margin: 0px 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}
.from-conent {
  margin: 20px 20px;
}
.cover-img {
  aspect-ratio: 16/9;
  width: 230px;
}
</style>
